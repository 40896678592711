/**
 * 后端应用地址
 * @type {string}
 */
export const URL_BASE = process.env.VUE_APP_API_URL;


export const publicPages = [
    // '/',
    '/login',
    '/user/password-update',
    '/permission-error'
];