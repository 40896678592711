<template>
  <div v-if="$route.path === '/wholeCaseHome'">
    <whole-case-home></whole-case-home>
  </div>
  <div v-else>
    <div>
      <preloader/>
      <back-top/>
      <top-header/>
      <main>
        <router-view></router-view>
        <div style="clear:both;"></div>
      </main>
      <bottom-footer/>
    </div>

  </div>

</template>

<script>
import TopHeader from "@/components/common/TopHeader.vue";
import BottomFooter from "@/components/common/BottomFooter.vue";
import Preloader from "@/components/common/Preloader.vue";
import BackTop from "@/components/common/BackTop.vue";
import WholeCaseHome from "@/components/show/WholeCaseHome.vue";

export default {
  components: {
    WholeCaseHome,
    BackTop,
    Preloader,
    BottomFooter,
    TopHeader
  },
  data() {
    return {}
  },
  created() {
    console.log("create gdnz web app...");
    console.log("this.$route.path = " + this.$route.path);
  },
};
</script>
