<template>
  <div>
    <div id="background-image">
      <ClickableArea
          v-for="(item, index) in clickableAreas"
          :key="index"
          :ref="`clickableAreaRef${index}`"
          :bg-img-height="bgImgEleHeight" :item-data="item.p"
          @area-clicked="showDetails(item.id)"
      />
      <el-dialog width="80%" :modal="false" append-to-body title="提示" :visible.sync="detail.dialog">
        <div>
          <item-detail v-if="detail.dialog" :id="detail.id"></item-detail>
          <!-- 在这里添加你的内容 -->
        </div>

      </el-dialog>
    </div>
  </div>
</template>


<script>

import ClickableArea from "@/components/show/ClickableArea.vue";
import ItemDetail from "@/components/show/ItemDetail.vue";
import {list} from "@/assets/wch/temp/data";

export default {
  name: 'WholeCaseHome',
  components: {ItemDetail, ClickableArea},
  data() {
    return {
      imgWidth: 0,
      imgHeight: 0,
      imgAspectRatio: 0, // 新增：存储图片的宽高比
      showDialog: false,

      bgImgEleHeight: 0,  // 新增：存储背景图片的高度

      clickableAreas: list,

      detail: {
        dialog: false,
        name: "",
        id: null,
      },

    };
  },

  updated() {
    this.adjustHeight();
  },

  mounted() {
    let img = new Image();
    img.src = require('@/assets/wch/门头灯箱广告_手机图.jpg');
    img.onload = () => {
      this.imgWidth = img.width;
      this.imgHeight = img.height;
      this.imgAspectRatio = this.imgWidth / this.imgHeight;
      this.$nextTick(this.adjustHeight);
    };

    window.addEventListener('resize', this.adjustHeight);
  },


  beforeDestroy() {
    // 在组件销毁前移除事件监听器
    window.removeEventListener('resize', this.adjustHeight);
  },
  methods: {
    adjustHeight() {
      const bgElement = document.getElementById('background-image');
      if (!bgElement) return;
      const computedHeight = window.innerWidth / this.imgAspectRatio;
      this.bgImgEleHeight = computedHeight;
      bgElement.style.height = `${computedHeight}px`;

      // 遍历所有的ClickableArea组件引用并调用updateItemSize方法
      this.clickableAreas.forEach((item, index) => {
        if (this.$refs[`clickableAreaRef${index}`]) {
          this.$refs[`clickableAreaRef${index}`][0].updateItemSize();
        }
      });
    },

    showDetails(id) {
      this.detail.dialog = true;
      var item = this.clickableAreas.filter(item => item.id === id)[0];
      this.detail.name = item?.name;
      this.detail.id = item.id;
    }
  }
};
</script>

<style scoped>
#background-image {
  width: 100vw;
  background-image: url('~@/assets/wch/门头灯箱广告_手机图.jpg');
  background-size: cover;
  background-position: center center;
  position: fixed;
}

::v-deep .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: #FFF;
  border-radius: 18px;
  box-shadow: 0 1px 9px 7px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 50%;
}

::v-deep .el-dialog__header {
  display: none !important;
}
</style>
