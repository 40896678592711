import {v4 as uuidv4} from "uuid";

export const list = [
    {p: {top: 36, left: 5, height: 35, width: 12}, name: "别墅电梯", id: uuidv4()},
    {p: {top: 37, left: 18.5, height: 34, width: 28}, name: "水系统中央空调", id: uuidv4()},
    {p: {top: 37, left: 47, height: 34, width: 22}, name: "氟系统中央空调", id: uuidv4()},
    {p: {top: 34.5, left: 69.5, height: 36.5, width: 20}, name: "全空气系统", id: uuidv4()},
    {p: {top: 38, left: 90, height: 33, width: 22}, name: "水处理系统", id: uuidv4()},
    {p: {top: 34.5, left: 112.5, height: 36.5, width: 16.5}, name: "生活热水系统", id: uuidv4()},
    {p: {top: 36, left: 129.5, height: 35, width: 8}, name: "污水提升", id: uuidv4()},
    {p: {top: 41, left: 143, height: 10.5, width: 33}, name: "新风/除湿系统", id: uuidv4()},
    {p: {top: 53, left: 142.5, height: 18, width: 32}, name: "电渗透防潮系统", id: uuidv4()},
]
