import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/components/home/Home.vue";
import ProjectCommer from "@/components/project/ProjectCommer.vue";
import reloadScripts from "@/assets/reloadScripts";
import ProjectHome from "@/components/project/ProjectHome.vue";
import ProjectDetails from "@/components/project/ProjectDetails.vue";
import WholeCaseHome from "@/components/show/WholeCaseHome.vue";

// 引入 Vuex store
// 引入页面组件

// 使用 VueRouter 插件
Vue.use(VueRouter);

const RouterView = {render: h => h('router-view')};

// 定义路由
const routes = [
    {
        path: '/', name: '首页', component: Home
    },
    {
        path: '/project', name: '服务项目', component: RouterView, children: [
            {
                path: 'home', name: '家用项目', component: ProjectHome,
            }, {
                path: 'commer', name: '商用项目', component: ProjectCommer,
            },
            {
                path: 'details/:id', name: '项目详情', component: ProjectDetails
            }

        ],
    },
    {
        path: '/wholeCaseHome', name: '机电全案首页', component: WholeCaseHome
    }

];

// 创建并导出 VueRouter 实例
const router = new VueRouter({
    mode: "hash", routes,
});

// 添加全局前置守卫
router.beforeEach(async (to, from, next) => {
    try {
        reloadScripts();

        // 检查目标路由是否与当前路由相同
        if (to.path === from.path) {
            // 如果相同，取消导航
            next(true);
        } else {
            Vue.prototype.$lastRoute = from;
            next();
        }

    } catch (e) {
        next();
    }
});


export default router;
