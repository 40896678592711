<template>
  <section class="blog-post-area pt-120 pb-90">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title mb-80">
            <span class="sub-title">项目介绍</span>
            <h2 class="tile">广东南芝中央空调商用项目</h2>
          </div>
        </div>
      </div>
      <div class="blog-post-wrap">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div class="blog-post-item">
              <div class="blog-post-thumb">
                <a><img src="../../../assets/img/blog/blog_post_img01.jpg" alt="img"></a>
              </div>
              <div class="blog-post-content">
                <div class="blog-meta">
                  <ul class="list-wrap">
                    <li><a href="#" class="underline-effect">广州海丝博物馆</a></li>
                    <li>多联机中央空调系统 / 161HP</li>
                  </ul>
                </div>
                <h3 class="title"><a>清远市清城区X405美林湖星汇半岛商业街</a></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="blog-post-item">
              <div class="blog-post-thumb">
                <a><img src="../../../assets/img/blog/blog_post_img02.jpg" alt="img"></a>
              </div>
              <div class="blog-post-content">
                <div class="blog-meta">
                  <ul class="list-wrap">
                    <li><a href="#" class="underline-effect">京东亚洲一号仓物流中心</a></li>
                    <li>多联机中央空调系统 / 2000HP</li>
                  </ul>
                </div>
                <h3 class="title"><a>广州市黄埔区凤凰一横路076乡道交叉口</a></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="blog-post-item">
              <div class="blog-post-thumb">
                <a><img src="../../../assets/img/blog/blog_post_img03.jpg" alt="img"></a>
              </div>
              <div class="blog-post-content">
                <div class="blog-meta">
                  <ul class="list-wrap">
                    <li><a href="#" class="underline-effect">广州成珊连锁酒店</a></li>
                    <li>多联机中央空调系统 / 244HP</li>
                  </ul>
                </div>
                <h3 class="title"><a>清远市石角镇美林湖国际社区星汇半岛南苑
                </a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'blog-post-area'
}
</script>
