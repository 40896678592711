<!--suppress CssInvalidPropertyValue -->
<template>
  <div>
    <page-title :title="type"/>

    <div style="height:100%">
      <section class="inner-project-area pt-120 pb-120">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="project-nav inner-project-nav">
                <ul class="list-wrap">
                  <li class="active" data-filter="*"><a href="#">所有</a></li>
                  <li class="non-clickable" data-filter=".cat-one"><a href="#">中央空调</a></li>
                  <li class="non-clickable" data-filter=".cat-two"><a href="#">新风系统</a></li>
                  <li class="non-clickable" data-filter=".cat-three"><a href="#">地暖系统</a></li>
                  <li class="non-clickable" data-filter=".cat-four"><a href="#">净水系统</a></li>
                </ul>
              </div>
            </div>
          </div>

          <div style="height: 600px" class="container-center" v-if="items.length === 0">
            <div class="info-text">无任何内容...</div>
          </div>

          <div v-else class="inner-project-wrap-two">
            <div class="row project-active-six">
              <div v-for="(item,index) in items" :key="index" style="margin-bottom:4px"
                   class="col-lg-3 col-sm-6 grid-item grid-sizer cat-three cat-one">
                <div class="project-item-six">
                  <div class="project-thumb-six">
                    <a @click="$router.push('/project/details/'+item.id)"><img style="height: 300px"
                                                                               :src="cloud.filename2Url(item.imgFileName) "
                                                                               alt="img"></a>
                  </div>
                  <div class="project-content-six">
                    <h2 class="title"><a @click="$router.push('/project/details/'+item.id)" class="underline-effect">
                      {{ item.title }}
                    </a></h2>
                    <div class="sub-title" style="margin-top: 2px">{{ item.subTitle }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="load-more-btn text-center mt-20">
              <a href="#" class="btn non-clickable"><span>更多</span></a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>


</template>

<script>

import PageTitle from "@/components/project/PageTitle.vue";
import {vpost} from "@/api/post";
import {cloud} from "@/api/wxx-cloud";

export default {
  name: 'Projects',
  computed: {
    cloud() {
      return cloud
    }
  },
  components: {PageTitle},
  props: {
    type: String,
  },

  data() {
    return {
      items: []
    };
  },
  created() {
    var formData = new FormData();
    formData.append("type", this.type);
    vpost("/web/listProjects2Web", formData).then(result => {
      this.items = result.data;
      this.$forceUpdate();
      // window.location.reload();
    });
  }
};
</script>

<style scoped>
h2.title {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  max-width: 100%; /* 最大宽度为容器的宽度 */
  margin-bottom: 12px
}

.sub-title {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制内容为2行 */
  -webkit-box-orient: vertical;
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  max-width: 100%; /* 最大宽度为容器的宽度 */
  line-height: 1.2em; /* 假设每行的行高是1.2em */
  height: 2.4em; /* 2行的总高度 */
  position: relative;
  padding-bottom: 1.2em; /* 和line-height一致 */
}

.sub-title::after {
  content: '\200B'; /* 使用零宽度空白字符来确保最少两行的高度 */
  display: block;
}


.project-active-six {
  height: 100%
}

</style>