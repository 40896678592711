// noinspection DuplicatedCode

import axios from 'axios';

import {publicPages, URL_BASE} from '@/api/base-val';

export function axiosConfig() {
    const instance = axios.create({
        baseURL: URL_BASE,
        withCredentials: true,
        timeout: 500000,
    });

    // 请求拦截器
    instance.interceptors.request.use(config => {
        // 从你的存储中获取JWT
        const token = window.localStorage.getItem("token");
        if (token !== null) {

            // 如果有JWT，将其设置为Authorization头
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });
    return instance;
}

const instance = axiosConfig();

export async function vpost(url, params) {
    return new Promise((resolve, reject) => {
        instance.post(url, params).then(result => {
            resolve(result.data);
        }).catch(error => {
            var pathname = window.location.pathname;

            if (!publicPages.includes(pathname) && error.response && (error.response.status === 401)) {

                window.localStorage.removeItem("token");

                window.open("/login", "_self");

                // 重新发送请求到Location头的URL
                const redirectUrl = error.response.headers.location;
                instance.post(redirectUrl, params).then(result => {
                    resolve(result.data);
                }).catch(error => {
                    reject(error);
                });
            } else {
                reject(error);
            }
        });
    });
}


