var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"slider-area"},[_c('div',{staticClass:"slider-active"},[_c('div',{staticClass:"single-slider slider-bg",style:(_vm.backgroundImage1)},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-7 col-lg-8"},[_c('div',{staticClass:"slider-content"},[_vm._m(0),_c('div',{staticClass:"slider-avatar",attrs:{"data-animation":"fadeInUp","data-delay":".6s"}},[_c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":require('@/assets/img/slider/slider_avatar01.png'),"alt":"img"}})]),_vm._m(1)])])])])])]),_c('div',{staticClass:"single-slider slider-bg",style:(_vm.backgroundImage2)},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-7 col-lg-8"},[_c('div',{staticClass:"slider-content"},[_vm._m(2),_c('div',{staticClass:"slider-avatar",attrs:{"data-animation":"fadeInUp","data-delay":".6s"}},[_c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":require('@/assets/img/slider/slider_avatar01.png'),"alt":"img"}})]),_vm._m(3)])])])])])]),_c('div',{staticClass:"single-slider slider-bg",style:(_vm.backgroundImage3)},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-7 col-lg-8"},[_c('div',{staticClass:"slider-content"},[_vm._m(4),_c('div',{staticClass:"slider-avatar",attrs:{"data-animation":"fadeInUp","data-delay":".6s"}},[_c('div',{staticClass:"thumb"},[_c('img',{attrs:{"src":require('@/assets/img/slider/slider_avatar01.png'),"alt":"img"}})]),_vm._m(5)])])])])])])]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"title",attrs:{"data-animation":"fadeInUp","data-delay":".3s"}},[_vm._v(" 您不能改变大气候 "),_c('br'),_vm._v(" 但可以调节居家小环境")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h6',{staticClass:"name"},[_vm._v("东芝空调，让舒适无处不在")]),_c('span',[_vm._v("无论季节如何变换，东芝空调都带给您舒适宜人的家")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"title",attrs:{"data-animation":"fadeInUp","data-delay":".3s"}},[_vm._v(" 南芝舒适家为您提供 "),_c('br'),_vm._v(" 一站式气候整体解决方案")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h6',{staticClass:"name"},[_vm._v("技术领先，环保节能")]),_c('span',[_vm._v("东芝空调，以科技引领节能，为您的生活创造绿色未来")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"title",attrs:{"data-animation":"fadeInUp","data-delay":".3s"}},[_vm._v(" 智能自清洗功能 "),_c('br'),_vm._v(" 享受健康清新的空气")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h6',{staticClass:"name"},[_vm._v("自清洗功能，健康又安心")]),_c('span',[_vm._v("一键自清洗，从此告别霉味，东芝空调，让清新永驻您的家")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slick-counter"},[_c('span',{staticClass:"current"}),_vm._v(" / "),_c('span',{staticClass:"total"})])
}]

export { render, staticRenderFns }