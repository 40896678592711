<template>
  <div>
    <projects type="商用项目"></projects>
  </div>
</template>

<script>
import Projects from "@/components/project/Projects.vue";

export default {
  name: 'ProjectCommer',
  components: {Projects},
};
</script>

<style scoped>
</style>