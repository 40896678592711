// reloadScripts.js
export default function reloadScripts() {
    var scriptList = [
        "./assets/js/bootstrap.min.js",
        "./assets/js/jquery.magnific-popup.min.js",

        "./assets/js/isotope.pkgd.min.js",
        "./assets/js/jarallax.min.js",
        "./assets/js/jarallax-video.min.js",
        "./assets/js/slick.min.js",
        "./assets/js/swiper-bundle.min.js",

        "./assets/js/wow.min.js",
        "./assets/js/animated-headline.min.js",

        "./assets/js/ScrollTrigger.min.js",
        "./assets/js/charming.min.js",
        "./assets/js/TweenMax.min.js",

        "./assets/js/demo.js",
        "./assets/js/ajax-form.js",

        "./assets/js/jquery.odometer.min.js",
        "./assets/js/jquery.appear.js",
        "./assets/js/Sticky-kit.min.js",
        "./assets/js/jquery.easypiechart.min.js",
        "./assets/js/jquery.inview.min.js",
    ];

    var loadScript = function (src) {
        return new Promise(function (resolve, reject) {
            var script = document.createElement("script");
            script.src = src;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    }

    var promises = scriptList.map(loadScript);

    Promise.all(promises)
        .then(function () {
            return loadScript("./assets/js/main.js");
        })
        .catch(function (error) {
            console.error("Failed to load script", error);
        });
}
