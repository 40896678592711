<template>
  <section class="services-area">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title mb-80">
            <span class="sub-title">服务</span>
            <h2 class="title">我们为您提供什么服务</h2>
          </div>
        </div>
      </div>
      <div class="services-item-wrap">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <div class="services-icon">
                <img src="../../../assets/img/icons/services_icon01.svg" alt="img">
              </div>
              <div class="services-content">
                <h2 class="title"><a href="#">室内环境解决方案</a></h2>
                <span class="divider"></span>
                <p>从中央空调到新风系统，我们提供全套室内环境整体解决方案</p>
                <div class="services-btn">
                  <a href="#" class="link-btn">查看详情</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <div class="services-icon">
                <img src="../../../assets/img/icons/services_icon02.svg" alt="img">
              </div>
              <div class="services-content">
                <h2 class="title"><a href="#">生活用水系统</a></h2>
                <span class="divider"></span>
                <p>净水软水系统、热水系统等，打造安全、健康的水环境</p>
                <div class="services-btn">
                  <a href="#" class="link-btn">查看详情</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="services-item">
              <div class="services-icon">
                <img src="../../../assets/img/icons/services_icon03.svg" alt="img">
              </div>
              <div class="services-content">
                <h2 class="title"><a href="#">智能控制与舒适家电</a></h2>
                <span class="divider"></span>
                <p>智能控制系统和舒适家电产品，为您的生活提供便捷和舒适</p>
                <div class="services-btn">
                  <a href="#" class="link-btn">查看详情</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'services-area'
}
</script>