<!--suppress JSJQueryEfficiency -->
<template>
  <section class="slider-area">
    <div class="slider-active">
      <div class="single-slider slider-bg" :style="backgroundImage1">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-8">
              <div class="slider-content">
                <h2 class="title" data-animation="fadeInUp" data-delay=".3s">
                  您不能改变大气候
                  <br>
                  但可以调节居家小环境</h2>
                <div class="slider-avatar" data-animation="fadeInUp" data-delay=".6s">
                  <div class="thumb">
                    <img :src="require('@/assets/img/slider/slider_avatar01.png')" alt="img">
                  </div>
                  <div class="content">
                    <h6 class="name">东芝空调，让舒适无处不在</h6>
                    <span>无论季节如何变换，东芝空调都带给您舒适宜人的家</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="single-slider slider-bg" :style="backgroundImage2">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-8">
              <div class="slider-content">
                <h2 class="title" data-animation="fadeInUp" data-delay=".3s">
                  南芝舒适家为您提供
                  <br>
                  一站式气候整体解决方案</h2>
                <div class="slider-avatar" data-animation="fadeInUp" data-delay=".6s">
                  <div class="thumb">
                    <img :src="require('@/assets/img/slider/slider_avatar01.png')" alt="img">
                  </div>
                  <div class="content">
                    <h6 class="name">技术领先，环保节能</h6>
                    <span>东芝空调，以科技引领节能，为您的生活创造绿色未来</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="single-slider slider-bg" :style="backgroundImage3">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-8">
              <div class="slider-content">
                <h2 class="title" data-animation="fadeInUp" data-delay=".3s">
                  智能自清洗功能 <br>
                  享受健康清新的空气</h2>
                <div class="slider-avatar" data-animation="fadeInUp" data-delay=".6s">
                  <div class="thumb">
                    <img :src="require('@/assets/img/slider/slider_avatar01.png')" alt="img">
                  </div>
                  <div class="content">
                    <h6 class="name">自清洗功能，健康又安心</h6>
                    <span>一键自清洗，从此告别霉味，东芝空调，让清新永驻您的家</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="slick-counter"><span class="current"></span> / <span class="total"></span></div>
  </section>
</template>
<script>
export default {
  name: 'banner',

  methods: {
    initializeSlider() {
      try {
        this.$nextTick(() => {
          if ($('.slider-active').hasClass('slick-initialized')) {
            $('.slider-active').slick('unslick');  // 如果已经初始化过，先销毁
          }
          $('.slider-active').slick({
            arrows: false,  // 禁用箭头按钮
            // 可以加入其他配置参数
          });
        });
      } catch (e) {
      }
    }
  },


  computed: {
    backgroundImage1() {
      return {backgroundImage: `url(${require('@/assets/img/slider/slider_bg01.jpg')})`};
    },
    backgroundImage2() {
      return {backgroundImage: `url(${require('@/assets/img/slider/slider_bg02.jpg')})`};
    },
    backgroundImage3() {
      return {backgroundImage: `url(${require('@/assets/img/slider/slider_bg03.jpg')})`};
    },

  },
  mounted() {
    this.initializeSlider();
  },

  watch: {
    '$route'(to, from) {
      if (to.name === 'Home' && from.name === '家用项目') {
        this.initializeSlider();
      }
    }
  }


}
</script>
