<template>
  <div>
    <page-title title="项目详情"></page-title>
    <section class="project-details-area pt-120 pb-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="project-details-img">
              <img :src="cloud.filename2Url(vo.imgFileName)" alt="img">
            </div>
            <div class="project-details-wrap">
              <div class="row">
                <div class="col-lg-8">
                  <div class="project-details-content">
                    <div v-if="vo === {}">
                      <el-skeleton :rows="6" animated/>
                    </div>
                    <div v-else>
                      <h2 class="title">{{ vo.title }}</h2>

                      <div v-if="vIsEmpty(vo.content)" class="info-text">
                        该项目没有介绍...
                      </div>

                      <div v-else v-html="vo.content"></div>

                    </div>

                  </div>
                </div>
                <div class="col-lg-4">
                  <aside class="project-sidebar">
                    <div class="project-widget">
                      <h4 class="widget-title">项目信息</h4>
                      <div class="project-info">
                        <ul class="list-wrap">
                          <li>项目/楼盘<span>{{ vo.title }}</span></li>
                          <li>项目配置<span>咨询南芝</span></li>
                          <li>备注<span>{{ vo.subTitle }}</span></li>
                        </ul>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import PageTitle from "@/components/project/PageTitle.vue";
import {vpost} from "@/api/post";
import {vIsEmpty} from "@/assets/my-js";
import {cloud} from "@/api/wxx-cloud";

export default {
  name: 'ProjectDetails',
  computed: {
    cloud() {
      return cloud
    }
  },
  methods: {
    vIsEmpty,
  },
  components: {PageTitle},
  data() {
    return {
      id: this.$route.params.id,

      vo: {},

    };
  },

  created() {
    vpost("/web/getWebProjectVo?id=" + this.id).then(result => {
      this.vo = result.data;
    });
  }

};
</script>

<style scoped>

.project-info span {
  display: inline-block; /* 使span变成行内块元素，以便可以设置宽度 */
  max-width: 17ch; /* 设置最大宽度为20个字符（ch是一个单位，表示“0”字符的宽度，但在大多数字体中，这近似于平均字符宽度） */
  white-space: nowrap; /* 防止内容换行 */
  overflow: hidden; /* 隐藏超出的部分 */
  text-overflow: ellipsis; /* 在超出的部分显示省略号 */
  vertical-align: bottom; /* 对齐到底部以防止其他不期望的行高差异 */
}

</style>
