<template>
  <section class="about-area pt-120 pb-120">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title mb-80">
            <span class="sub-title">关于我们</span>
            <h2 class="title">南芝舒适家®，一站式解决室内环境问题，提升家居生活品质的综合服务平台。</h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-4 col-md-8">
          <div class="about-img">
            <img src="../../../assets/img/images/about_img01.jpg" alt="img">
          </div>
        </div>
        <div class="col-lg-8">
          <div class="about-content">
            <div class="left-side-content">
              <p>
                南芝舒适家®是一家致力于解决室内空气、生活用水、智能控制、舒适家电等集成体系，努力提升家居室内环境品质的综合性服务平台。平台整合了中央空调系统、中央新风系统、生活热水系统、净水软水系统、地暖采暖系统、中央除尘系统、别墅电梯系统、地下室排污系统、智能控制系统、家用电器等产品，为广大客户提供一站式室内舒适家居系统整体解决方案。</p>
              <a class="btn"><span>联系我们</span></a>
            </div>
            <div class="right-side-content">
              <span>荣获以下资质/奖项</span>
              <ul class="list-wrap">
                <li><a href="#">➢东芝空调设备特约经销商</a></li>
                <li><a href="#">➢东芝空调2022年TCS专业店</a></li>
                <li><a href="#">➢东芝空调特约售后服务网点</a></li>
                <li><a href="#">➢东芝系列商用空调安装商</a></li>
                <li><a href="#">➢芝匠安装认证店</a></li>
                <li><a href="#">&nbsp;&nbsp;&nbsp;&nbsp;...</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'about-area'
}
</script>
