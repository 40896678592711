<template>
  <div id="preloader">
    <div class="ta-preloader-block">
      <div class="ta-spinner-eff">
        <div class="ta-bar ta-bar-top"></div>
        <div class="ta-bar ta-bar-right"></div>
        <div class="ta-bar ta-bar-bottom"></div>
        <div class="ta-bar ta-bar-left"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'preloader'
}
</script>