<template>
  <section class="breadcrumb-area breadcrumb-bg" data-background="assets/img/bg/breadcrumb_bg.jpg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="breadcrumb-content">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">首页</a></li>
                <li class="breadcrumb-item active" aria-current="page">{{ title }}</li>
              </ol>
            </nav>
            <h2 class="title">{{ title }}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'page-title',
  props: {
    title: String,
  }
}
</script>