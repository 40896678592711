// noinspection DuplicatedCode

import {axiosConfig} from "@/api/post";

export const URL = "https://gdnz.cc/wxxcloud";

export function filename2Url(filename) {
    return URL + "/view/" + filename;
}

export async function filename2File(filename) {
    try {

        var url = filename2Url(filename);

        // 使用fetch API从URL获取数据
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('网络响应错误');
        }

        // 将网络响应转换为Blob
        const blob = await response.blob();

        // 从URL中解析出文件名
        const urlSegments = url.split('/');
        const fileName = urlSegments[urlSegments.length - 1];

        // 创建一个File对象
        const file = new File([blob], fileName, {type: blob.type});

        return file;
    } catch (error) {
        console.error('获取文件失败:', error);
        throw error;
    }
}

export const cloud = {
    URL: "https://gdnz.cc/wxxcloud",
    filename2Url: (filename) => {
        return URL + "/view/" + filename;
    },
    download: (filename) => {
        instance({
            method: 'get',
            url: URL + `/download`, // 注意：这里的 URL 应该与你的后端下载接口的 URL 对应
            params: {filename},
            responseType: 'blob', // 表明返回服务器返回的数据类型
        })
            .then((response) => {
                const blob = new Blob([response.data], {type: response.data.type || 'application/octet-stream'});
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = filename; // 文件名
                document.body.appendChild(a);
                a.click(); // 模拟点击a标签，实现下载
                document.body.removeChild(a);
                window.URL.revokeObjectURL(downloadUrl);  // 释放URL对象，优化性能
            })
            .catch((error) => {
                console.error(error);
            });
    },
    downloadTemp: (filename) => {
        instance({
            method: 'get',
            url: URL + `/downloadTemp`, // 注意：这里的 URL 应该与你的后端下载接口的 URL 对应
            params: {filename},
            responseType: 'blob', // 表明返回服务器返回的数据类型
        })
            .then((response) => {
                const blob = new Blob([response.data], {type: response.data.type || 'application/octet-stream'});
                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = filename; // 文件名
                document.body.appendChild(a);
                a.click(); // 模拟点击a标签，实现下载
                document.body.removeChild(a);
                window.URL.revokeObjectURL(downloadUrl);  // 释放URL对象，优化性能
            })
            .catch((error) => {
                console.error(error);
            });
    },
};


const instance = axiosConfig();