export function vIsEmpty(str) {

    // str = JSON.stringify(str).toString().replace(/\s/g, '');
    if (typeof str === 'string') {
        str = str.trim();
    }
    return str === "null" || str === null || str === "" || str === '' || str === undefined || str === "undefined";

}

export function vNotEmpty(str) {
    return !vIsEmpty(str);
}

/**
 * 图片转base64，用于vue中
 * @param file
 * @returns {string}
 */
export async function getBase64_vue(file) {
    return await new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
            imgResult = reader.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.onloadend = function () {
            resolve(imgResult);
        };
    });
}

/**
 * 修改当前浏览器地址参数（不跳转页面，不刷新，不重新加载）
 * @param arg 参数名
 * @param arg_val 新的参数值
 * @returns {*|string|string}
 */
export function changeURLArg(arg, arg_val) {

    // 获取当前浏览器完整地址
    let url = window.location.href;

    // if (isEmpty(getUrlParameter(arg))) {
    //     url += "&" + arg + "=" + arg_val;
    // }

    // 处理后新的地址
    var newUrl;

    // 进行参数替换
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        newUrl = url.replace(eval(tmp), replaceText);
        // return tmp;
    } else {
        if (url.match('[\?]')) {
            newUrl = url + '&' + replaceText;
        } else {
            newUrl = url + '?' + replaceText;
        }
    }

    // 得到新的地址
    // newUrl = url + '\n' + arg + '\n' + arg_val;

    // console.log("newUrl = " + newUrl);

    clearUrlParameters();

    // 替换地址栏
    history.replaceState(null, null, newUrl);
    // return url + '\n' + arg + '\n' + arg_val;


}

/** 清空浏览器地址栏查询参数 */
export function clearUrlParameters() {
    let url = window.location.href;
    if (url.indexOf('?') !== -1) {
        url = url.replace(/([?#])[^'"]*/, '');
        window.history.pushState({}, 0, url);
    }
}

export function vIfEmptyToStr(str, toStr) {
    return vIsEmpty(str) ? toStr : str;
}

/**
 * 获取url中的参数
 * @param name  参数名
 * @returns {string|null}
 * @constructor
 */
export function getUrlParameter(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]);
    return null;
}

export function format(date) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function clearAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

/**
 * 获取当前时间
 * @param formatStr 指定的时间格式
 * @returns {*}
 */
export function getNow(formatStr) {
    var now = new Date(); // 获取当前日期时间对象
    var year = now.getFullYear(); // 获取年份
    var month = now.getMonth() + 1; // 获取月份（注意月份从0开始，需要加1）
    var day = now.getDate(); // 获取日期
    var hour = now.getHours(); // 获取小时
    var minute = now.getMinutes(); // 获取分钟
    var second = now.getSeconds(); // 获取秒数

    // 按照传入的格式进行格式化
    return formatStr
        .replace('yyyy', year)
        .replace('MM', padZero(month))
        .replace('dd', padZero(day))
        .replace('HH', padZero(hour))
        .replace('mm', padZero(minute))
        .replace('ss', padZero(second));
}

function padZero(value) {
    return value < 10 ? '0' + value : value; // 将个位数补零
}

/**
 * 从数组中随机获取指定数量的元素，确保获取的元素不重复
 * @param {Array} arr - 输入的数组
 * @param {number} count - 需要获取的元素数量
 * @returns {Array} - 随机获取的元素数组
 */
export function getRandomElements(arr, count) {
    if (count > arr.length) {
        throw new Error("指定的数量超过数组长度");
    }

    // 复制原数组，以免修改原数组
    const copyArr = arr.slice();

    // 用于存储获取的随机元素
    const result = [];

    // 从复制的数组中随机获取元素
    while (result.length < count) {
        const randomIndex = Math.floor(Math.random() * copyArr.length);
        const element = copyArr[randomIndex];

        // 确保元素不重复
        if (!result.includes(element)) {
            result.push(element);
            copyArr.splice(randomIndex, 1); // 从复制的数组中删除已获取的元素
        }
    }

    return result;
}