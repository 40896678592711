<template>
  <div>
    <banner/>
    <about-area/>

    <!-- brand-area -->
    <div class="brand-area pb-120">
      <div class="container">
        <div class="row brand-active">
          <div class="col-12">
            <div class="brand-item">
              <img src="@/assets/img/brand/brand_img01.png" alt="img">
            </div>
          </div>
          <div class="col-12">
            <div class="brand-item">
              <img src="@/assets/img/brand/brand_img02.png" alt="img">
            </div>
          </div>
          <div class="col-12">
            <div class="brand-item">
              <img src="@/assets/img/brand/brand_img03.png" alt="img">
            </div>
          </div>
          <div class="col-12">
            <div class="brand-item">
              <img src="@/assets/img/brand/brand_img04.png" alt="img">
            </div>
          </div>
          <div class="col-12">
            <div class="brand-item">
              <img src="@/assets/img/brand/brand_img05.png" alt="img">
            </div>
          </div>
          <div class="col-12">
            <div class="brand-item">
              <img src="@/assets/img/brand/brand_img06.png" alt="img">
            </div>
          </div>
        </div>
      </div>
    </div>
    <services-area/>
    <!--<project-area/>-->
    <!--<counter-area/>-->
    <marquee-area/>
    <!--<testimonial-area/>-->
    <!--<faq-area/>-->
    <blog-post-area/>
  </div>
</template>

<script>
import Preloader from "@/components/common/Preloader.vue";
import BackTop from "@/components/common/BackTop.vue";
import TopHeader from "@/components/common/TopHeader.vue";
import BottomFooter from "@/components/common/BottomFooter.vue";
import Banner from "@/components/home/common/Banner.vue";
import MarqueeArea from "@/components/home/common/MarqueeArea.vue";
import BlogPostArea from "@/components/home/common/BlogPostArea.vue";
import ServicesArea from "@/components/home/common/ServicesArea.vue";
import AboutArea from "@/components/home/common/AboutArea.vue";

export default {
  name: 'Home',
  created() {
    console.log("home...");
  },
  components: {
    AboutArea,
    ServicesArea,
    BlogPostArea, MarqueeArea, Banner, BottomFooter, TopHeader, BackTop, Preloader
  },

};
</script>

