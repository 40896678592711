<template>
  <footer>
    <div class="footer-area">
      <div class="container">
        <div class="footer-top">
          <div class="row">
            <div class="col-xl-5 col-lg-4 col-md-6">
              <div class="footer-widget">
                <div class="fw-logo">
                  <a><img src="@/assets/img/logo/logo.png" alt="img"></a>
                </div>
                <div class="footer-content">
                  <p>给您最好的舒适</p>
                  <ul class="list-wrap">
                    <li>
                      <a href="mailto:info@kewi.design.com" class="underline-effect">https://www.gdnz.cc</a>
                    </li>
                    <li>
                      <a href="tel:0123456789" class="underline-effect">020-3170-7642</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-6">
              <div class="footer-widget">
                <h4 class="fw-title">导航</h4>
                <div class="footer-link-list">
                  <ul class="list-wrap">
                    <li><a>首页</a></li>
                    <li><a>关于我们</a></li>
                    <li><a>服务网点</a></li>
                    <li><a>施工服务</a></li>
                    <li><a>家装案例</a></li>
                    <li><a>商用案例</a></li>
                    <li><a>产品介绍</a></li>
                    <li><a>南芝招聘</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4">
              <div class="footer-widget">
                <h4 class="fw-title">联系我们</h4>
                <div class="footer-newsletter">
                  <p>请留下您的联系方式，我们会在一个工作日内主动联系您</p>
                  <form action="#">
                    <div class="form-grp">
                      <input id="phone" type="email" placeholder="请输入您的手机号码">
                      <label for="email"></label>
                    </div>
                    <div class="form-grp">
                      <input id="name" type="email" placeholder="请输入您的称呼">
                      <label for="email"></label>
                    </div>
                    <button type="submit" class="submit-btn non-clickable">提交 <i class="flaticon-up"></i></button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="row align-items-center">
            <div class="col-md-8">
              <div class="copyright-text">
                <p>Copyright 粤ICP备2021140969号 © 2020-2022</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'bottom-footer'
}
</script>