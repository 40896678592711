<template>
  <div :id="itemId" @click="showDetails" :style="areaStyle"></div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  props: {
    // 传入背景图片高度
    bgImgHeight: {
      type: Number,
      required: true
    },
    // 传入点击区域的样式数据
    itemData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      itemId: uuidv4(),
      areaStyle: {}
    };
  },
  mounted() {
    this.updateItemSize();
  },


  methods: {
    showDetails() {
      this.$emit('area-clicked', this.itemId);
    },
    updateItemSize() {
      const bgImgHeight = this.bgImgHeight;  // 直接使用传入的高度值

      const itemTop = bgImgHeight * (this.itemData.top / 100);
      const itemHeight = bgImgHeight * (this.itemData.height / 100);
      const itemLeft = bgImgHeight * (this.itemData.left / 100);
      const itemWidth = bgImgHeight * (this.itemData.width / 100);

      this.areaStyle = {
        top: `${itemTop}px`,
        height: `${itemHeight}px`,
        left: `${itemLeft}px`,
        width: `${itemWidth}px`,
        border: '1px solid #707070',
        background: '#ffffff',
        // 透明度
        opacity: '0',
        // opacity: '0.5',
        borderRadius: '6px',
        zIndex: '999',
        position: 'absolute'
      };
    }
  }
};
</script>
