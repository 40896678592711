<template>
  <div class="extra-info">
    <div class="close-icon menu-close">
      <button><i class="far fa-window-close"></i></button>
    </div>
    <div class="logo-side mb-30">
      <a><img src="@/assets/img/logo/logo_black.png" alt="Logo"></a>
    </div>
    <div class="side-info mb-30">
      <div class="contact-list mb-30">
        <h4>联系电话</h4>
        <p>总部：020-3170-7642 <br>
          传真：nanzhi007@163.com <br>
        </p>
      </div>
      <div class="contact-list mb-30">
        <h4>总部地址/旗舰店</h4>
        <p>广东省广州市天河区汇彩路10号101-102-201号店</p>
      </div>
    </div>
    <ul class="side-instagram list-wrap">
      <li><a><img src="@/assets/img/images/sb_insta01.jpg" alt="img"></a></li>
      <li><a><img src="@/assets/img/images/sb_insta06.jpg" alt="img"></a></li>
      <li><a><img src="@/assets/img/images/sb_insta02.jpg" alt="img"></a></li>
      <li><a><img src="@/assets/img/images/sb_insta03.jpg" alt="img"></a></li>
      <li><a><img src="@/assets/img/images/sb_insta04.jpg" alt="img"></a></li>
      <li><a><img src="@/assets/img/images/sb_insta05.jpg" alt="img"></a></li>
    </ul>
    <!--<div class="social-icon-right mt-30">-->
    <!--  <a ><i class="fab fa-facebook-f"></i></a>-->
    <!--  <a ><i class="fab fa-twitter"></i></a>-->
    <!--  <a ><i class="fab fa-google-plus-g"></i></a>-->
    <!--  <a ><i class="fab fa-instagram"></i></a>-->
    <!--</div>-->
  </div>
</template>
<script>
export default {
  name: 'offCanvas-menu'
}
</script>
