<template>
  <el-row :gutter="20">
    <el-col :span="10">
      <div class="img-area"></div>
    </el-col>
    <el-col :span="14">
      <div style="padding-top: 8px;padding-bottom: 12px">
        <div style="font-weight: bold;font-size: 18px">工程师姓名</div>
        <div style="font-size: 13px;color: #939393;margin-bottom: 6px">南芝舒适家 XXXX工程师</div>
        <line-overflow :max-lines="4" content="东芝中央空调佛山地区优秀设计奖、东芝空调认证高级设计师拥有四年暖通专业设计经验。主要负责项目：增城风凰城；保利领秀、侨建御溪谷、锦绣香江、誉山国际、锦林山庄、天辰原著、珠江帝景、珠江御景花园、保利西海岸等，总服务工程三百余户。"></line-overflow>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import LineOverflow from "@/components/base/LineOverflow.vue"

export default {
  name: 'auth-detail-card',
  components: {LineOverflow}
}
</script>
<style scoped>
.img-area {
  background-color: #f5f5f5;
  color: #adadad;
  font-size: 40px;
  font-weight: bold;
  padding: 80px;
  text-align: center;
  border-radius: 6px;
}
</style>
