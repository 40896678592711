<template>
  <header>
    <div id="sticky-header" class="menu-area transparent-header">
      <div class="container custom-container">
        <div class="row">
          <div class="col-12">
            <div class="mobile-nav-toggler">
              <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.75 8.75H1.25V11.25H18.75V8.75Z" stroke="currentcolor" stroke-miterlimit="10"
                      stroke-linecap="square"/>
                <path d="M18.75 2.5H1.25V5H18.75V2.5Z" stroke="currentcolor" stroke-miterlimit="10"
                      stroke-linecap="square"/>
                <path d="M18.75 15H1.25V17.5H18.75V15Z" stroke="currentcolor" stroke-miterlimit="10"
                      stroke-linecap="square"/>
              </svg>
            </div>
            <div class="menu-wrap">
              <nav class="menu-nav">
                <div class="logo">
                  <a><img src="@/assets/img/logo/logo_white.png" alt="Logo"></a>
                </div>
                <div class="logo d-none">
                  <a><img class="logo-img" src="@/assets/img/logo/logo_black.png" alt="Logo"></a>
                </div>
                <div class="navbar-wrap main-menu d-none d-lg-flex">
                  <ul class="navigation">
                    <li><a @click.prevent="doNothing" href="#" @click="$router.push('/')">首页</a></li>
                    <li><a href="#" class="non-clickable">关于我们</a></li>
                    <li><a href="#" class="non-clickable">施工服务</a></li>
                    <li class="menu-item-has-children"><a href="#" target="_self">案例</a>
                      <ul class="sub-menu">
                        <li>
                          <a @click.prevent="doNothing" href="#" @click="$router.push('/project/home')">家用案例</a>
                        </li>
                        <li>
                          <a @click.prevent="doNothing" href="#" @click="$router.push('/project/commer')">商用案例</a>
                        </li>
                      </ul>
                    </li>
                    <li><a class="non-clickable">产品介绍</a></li>
                    <li class="menu-item-has-children"><a href="#" target="_self">服务与平台</a>
                      <ul class="sub-menu">
                        <li><a href="https://www.gdnz.cc/gim" target="_self">GIM综合管理平台</a></li>
                        <li><a href="https://www.gdnz.cc/mall" target="_self">订货商城</a></li>
                        <li><a href="https://www.gdnz.cc/csm" target="_self">CSM施工管理</a></li>
                        <li><a class="non-clickable" target="_self">客户售后中心</a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
                <div class="header-action d-none d-md-block">
                  <ul class="list-wrap">
                    <li class="header-search">
                      <a>
                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.1875 12.1875L17.5 17.5" stroke="currentcolor" stroke-miterlimit="10"
                                stroke-linecap="square"/>
                          <path
                              d="M8.125 13.75C11.2316 13.75 13.75 11.2316 13.75 8.125C13.75 5.0184 11.2316 2.5 8.125 2.5C5.0184 2.5 2.5 5.0184 2.5 8.125C2.5 11.2316 5.0184 13.75 8.125 13.75Z"
                              stroke="currentcolor" stroke-miterlimit="10" stroke-linecap="square"/>
                        </svg>
                      </a>
                    </li>
                    <li class="offcanvas-menu">
                      <a class="menu-tigger">
                        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.75 8.75H1.25V11.25H18.75V8.75Z" stroke="currentcolor" stroke-miterlimit="10"
                                stroke-linecap="square"/>
                          <path d="M18.75 2.5H1.25V5H18.75V2.5Z" stroke="currentcolor" stroke-miterlimit="10"
                                stroke-linecap="square"/>
                          <path d="M18.75 15H1.25V17.5H18.75V15Z" stroke="currentcolor" stroke-miterlimit="10"
                                stroke-linecap="square"/>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>

            <!-- Mobile Menu  -->
            <div class="mobile-menu">
              <nav class="menu-box">
                <div class="close-btn"><i class="fas fa-times"></i></div>
                <div class="nav-logo">
                  <a><img src="@/assets/img/logo/logo_black.png" alt="Logo"></a>
                </div>
                <div class="menu-outer">
                  <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
                </div>
                <!--<div class="social-links">-->
                <!--  <ul class="clearfix list-wrap">-->
                <!--    <li><a ><i class="fab fa-facebook-f"></i></a></li>-->
                <!--    <li><a ><i class="fab fa-twitter"></i></a></li>-->
                <!--    <li><a ><i class="fab fa-instagram"></i></a></li>-->
                <!--    <li><a ><i class="fab fa-linkedin-in"></i></a></li>-->
                <!--    <li><a ><i class="fab fa-youtube"></i></a></li>-->
                <!--  </ul>-->
                <!--</div>-->
              </nav>
            </div>
            <div class="menu-backdrop"></div>
            <!-- End Mobile Menu -->

          </div>
        </div>
      </div>
    </div>

    <!-- header-search -->
    <div class="search-popup-wrap" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="search-wrap text-center">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="search-form">
                <form action="#">
                  <input type="text" placeholder="请输入关键字搜索全站内容...(暂未开放)">
                  <button class="search-btn"><i class="flaticon-search"></i></button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search-backdrop"></div>
    <!-- header-search-end -->

    <!-- offCanvas-menu -->
    <offCanvas-menu/>
    <div class="offcanvas-overly"></div>
    <!-- offCanvas-menu-end -->

  </header>
</template>
<script>
import OffCanvasMenu from "@/components/common/OffCanvasMenu.vue";
import router from "@/router";

export default {
  name: 'top-header',
  methods: {
    router() {
      return router
    },

    doNothing() {

    },
  },
  components: {OffCanvasMenu},

}
</script>