<template>
  <div>
    <h3>{{ obj.name }}</h3>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="产品详情" name="产品详情"></el-tab-pane>
      <el-tab-pane label="工程团队" name="工程团队"></el-tab-pane>
      <el-tab-pane label="案例展示" name="案例展示"></el-tab-pane>
      <el-tab-pane label="施工规范" name="施工规范"></el-tab-pane>
    </el-tabs>

    <div class="space-between-y">
      <div v-if="activeName === '产品详情'">
        产品详情内容展示
      </div>
      <div v-if="activeName === '工程团队'">
        <el-row :gutter="30" style="padding-left: 12px;padding-right: 12px">
          <el-col v-for="i in 6" :span="12" style="margin-bottom: 14px">
            <auth-detail-card/>
          </el-col>
        </el-row>
      </div>


      <div v-for="(item,index) in 4" class="img-area" style="margin-bottom: 8px">
        {{ obj.name }}{{ activeName }}图片（{{ index + 1 }}）
      </div>

    </div>

  </div>
</template>

<script>
import {list} from "@/assets/wch/temp/data";
import AuthDetailCard from "@/components/show/AuthDetailCard.vue";

export default {
  name: 'ItemDetail',
  components: {AuthDetailCard},

  props: {
    id: String
  },


  created() {
    console.log("id = " + this.id);
    this.obj = list.filter(item => item.id === this.id)[0];
  },

  data() {
    return {
      obj: {},
      activeName: '工程团队'
    };
  },

  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style scoped>
.img-area {
  background-color: #f5f5f5;
  color: #adadad;
  font-size: 40px;
  font-weight: bold;
  padding: 80px;
  text-align: center;
  border-radius: 6px;
}
</style>
