<template>
  <div>
    <el-tooltip v-if="isOverflowing" placement="top">
      <div style="width: 200px" slot="content">
        <div>
          {{ content }}
        </div>
      </div>
      <div :id="id" ref="textContainer" class="text-container">
        {{ content }}
      </div>
    </el-tooltip>
    <div v-else>
      {{ content }}
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  name: "LineOverflow",
  props: {
    content: {
      type: String,
      required: true
    },
    maxLines: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      id: uuidv4(),
      isOverflowing: true // 新增此变量
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.textContainer) {
        this.$refs.textContainer.style.webkitLineClamp = this.maxLines.toString();
        this.isOverflowing = this.$refs.textContainer.scrollHeight > this.$refs.textContainer.clientHeight;
      }
    });
  }

};
</script>

<style scoped>
.text-container {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
}

.text-container .tooltip {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
}
</style>
