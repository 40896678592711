<template>
  <div class="marquee-area">
    <div class="marquee-wrap">
      <div class="marquee-box">
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
      </div>
      <div class="marquee-box">
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
        <a href="#" data-hover="南芝舒适家">南芝舒适家</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'marquee-area'
}
</script>